import React from 'react';
import PassIcon from '@material-ui/icons/CheckCircle';
import WarnIcon from '@material-ui/icons/Warning';
import FailIcon from '@material-ui/icons/Error';

import Spinner from './spinner';
import {
  CHECK_STATUS_PASS,
  CHECK_STATUS_FAIL,
  CHECK_STATUS_LOADING,
  CHECK_LEVEL_WARN,
  CHECK_LEVEL_FATAL,
} from '../lib/constants';

import styles from './check.scss';

const Check = ({ title, status, level, feedback, error }) => {
  const isLoading = status === CHECK_STATUS_LOADING;
  const hasSuccess = status === CHECK_STATUS_PASS;
  const hasWarning = status === CHECK_STATUS_FAIL && level === CHECK_LEVEL_WARN;
  const hasError = status === CHECK_STATUS_FAIL && level === CHECK_LEVEL_FATAL;
  return (
    <div className={`${styles.check} check-wrapper`}>
      <div className={`${styles.head} check-head`}>
        <div className={`${styles.title} check-head-title`}>{title}</div>
        {isLoading && <Spinner />}
        {hasSuccess && (
          <span className={`${styles.successIcon} check-icon-success`}>
            <PassIcon />
          </span>
        )}
        {hasWarning && (
          <span className={`${styles.warningIcon} check-icon-warning`}>
            <WarnIcon />
          </span>
        )}
        {hasError && (
          <span className={`${styles.errorIcon} check-icon-error`}>
            <FailIcon />
          </span>
        )}
      </div>
      {(hasWarning || hasError) && (
        <div className={`${styles.info} check-info`}>
          <div className={`${styles.feedback} check-info-feedback`}>{feedback}</div>
          {error && <div className={`${styles.error} check-info-error`}>{error}</div>}
        </div>
      )}
    </div>
  );
};

export default Check;
