import axios from 'axios';
import WebSocket from 'isomorphic-ws';

import { CHECK_STATUS_PASS, CHECK_STATUS_FAIL, CHECK_TYPE_HTTP, CHECK_TYPE_TCP } from './constants';

export const isIP = (ip) => /^(?!.*\.$)((?!0\d)(1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/.test(ip);

export const fetchChecksManifest = async () => {
  const response = await axios.get('https://cloudstorage.prod.screenscape.com/system/network/checks.json');
  return response.data;
};

/**
 * Processes a HTTP check returning true if passed
 * Returns the check with a .status property
 * @param {Object} check
 * @param {Object} check
 */
export const fetchHTTPCheckStatus = async (check) => {
  try {
    const result = await axios.get(check.url, {
      crossdomain: true,
      validateStatus: (status) => status < 500,
    });
    const expected = check.expect || 200;
    if (result.status !== expected) {
      throw new Error(`${check.url} returned HTTP ${result.status} but expected ${expected}`);
    }
  } catch (err) {
    if (check.expect && err.response && err.response.status && err.response.status === check.expect) {
      return { ...check, status: CHECK_STATUS_PASS };
    }
    return { ...check, status: CHECK_STATUS_FAIL, error: `Unable to reach ${check.url}` };
  }
  return { ...check, status: CHECK_STATUS_PASS };
};

/**
 * Processes a TCP check returning true if passed
 * Returns the check with a .status property
 * @param {Object} check
 * @param {Object} check
 */
export const fetchTCPCheckStatus = (check) =>
  new Promise((resolve) => {
    let ws = new WebSocket(`wss://${check.host}:${check.port}`);
    ws.onerror = (err) => {
      ws = null;
      resolve({ ...check, status: CHECK_STATUS_PASS });
    };
    setTimeout(() => {
      if (ws !== null) {
        ws = null;
        resolve({
          ...check,
          status: CHECK_STATUS_FAIL,
          error: `Connection to ${check.host} on port ${check.port} timed out.`,
        });
      }
    }, 30 * 1000);
  }).catch((err) => {
    resolve({ ...check, status: CHECK_STATUS_FAIL, error: err.message });
  });

export const fetchCheckStatus = async (check) => {
  const processed = await Promise.all(
    check.checks.map((c) => {
      if (c.type.toLowerCase() === CHECK_TYPE_HTTP) {
        return fetchHTTPCheckStatus(c);
      } else if (c.type.toLowerCase() === CHECK_TYPE_TCP) {
        return fetchTCPCheckStatus(c);
      } else {
        throw new Error(`Unrecognized check type: ${c.type} in check: ${check.title}`);
      }
    })
  );
  if (processed.some((c) => c.status === CHECK_STATUS_FAIL)) {
    return {
      ...check,
      status: CHECK_STATUS_FAIL,
      error: processed
        .filter((c) => typeof c.error !== 'undefined')
        .map((c) => c.error)
        .join(', '),
    };
  } else {
    return { ...check, status: CHECK_STATUS_PASS };
  }
};
