exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".check__check___2wSGc{display:flex;flex-direction:column;overflow:hidden;padding:1rem;border-bottom:1px solid #dadada;width:100%}.check__head___H23C7{display:flex;align-items:center;flex-direction:row;justify-content:space-between}.check__title___Sgamf{font-family:inherit;font-weight:500;line-height:1.1;color:inherit;font-size:1rem;margin:0 2rem 0 0;font-weight:bold}.check__successIcon___22A8e,.check__errorIcon___AeH4j{font-size:1.6rem;font-weight:bold;font-style:italic}.check__successIcon___22A8e{color:#4caf50}.check__errorIcon___AeH4j{color:#f44336}.check__warningIcon___1pr4M{color:#ff9800;font-weight:bold}.check__info___SCPjC{margin:0.4rem 0 0 0}.check__feedback____Ir93{line-height:1.4rem;color:rgba(0,0,0,0.6)}.check__error___1fVg7{margin:0.5rem 0 0 0;font-size:0.8rem;color:#6f6f6f;border-left:0.25rem solid #f34539;padding:0.2rem 0 0.1rem 0.5rem}\n", ""]);

// Exports
exports.locals = {
	"check": "check__check___2wSGc",
	"head": "check__head___H23C7",
	"title": "check__title___Sgamf",
	"successIcon": "check__successIcon___22A8e",
	"errorIcon": "check__errorIcon___AeH4j",
	"warningIcon": "check__warningIcon___1pr4M",
	"info": "check__info___SCPjC",
	"feedback": "check__feedback____Ir93",
	"error": "check__error___1fVg7"
};