import uuid from 'uuid/v4';
import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import { networkInterfaces } from 'os';
import publicIp from 'public-ip';

import logo from '../img/logo.png';

import './styles.scss';

import { NetworkCheck, Spinner, NetworkInfo } from './components';
import { ALL_RESULT_LOADING, ALL_RESULT_PASS, ALL_RESULT_FAIL, ALL_RESULT_WARN } from './lib/constants';

class Application extends Component {
  state = {
    key: uuid(),
    status: ALL_RESULT_LOADING,
    addr: '',
  };

  async componentDidMount() {
    try {
      const result = await publicIp.v4();
      this.setState({ addr: result });
    } catch (err) {
      this.setState({ addr: 'Unable to find your IP address' });
    }
  }

  setStatus(status) {
    this.setState({ status });
  }

  handlePass = () => {
    this.setStatus(ALL_RESULT_PASS);
  };

  handleFail = () => {
    this.setStatus(ALL_RESULT_FAIL);
  };

  handleWarn = () => {
    this.setStatus(ALL_RESULT_WARN);
  };

  handleRetry = () => {
    this.setState({ key: uuid(), status: ALL_RESULT_LOADING });
  };

  render() {
    const { status, key } = this.state;
    const warn = status === ALL_RESULT_WARN;
    const fatal = status === ALL_RESULT_FAIL;
    const passed = status === ALL_RESULT_PASS;
    const loading = status === ALL_RESULT_LOADING;

    const resultClass = fatal ? 'fatal' : warn ? 'warn' : passed ? 'pass' : 'loading';

    const controls = [];
    if (warn || fatal) {
      controls.push(<button onClick={this.handleRetry}>Check Again</button>);
    }

    let message = '';
    if (loading) {
      message = 'Checking your network to ensure all ScreenScape services can be reached.';
    }
    if (fatal) {
      message = 'Your network appears to be restricting access to some important ScreenScape services.';
    }
    if (warn) {
      message = 'Your network is restricting access to some ScreenScape services.';
    }
    if (passed) {
      message = 'You should have no problems using ScreenScape services from this network.';
    }

    const title = fatal ? 'Uhoh!' : warn ? 'Warning' : passed ? 'All Clear!' : 'Checking Network';

    return (
      <div className="container">
        <img className="logo" src={logo} />
        <div className="wrapper">
          <div className={`result ${resultClass}`}>
            <div className="title">{title}</div>
            <div className="message">{message}</div>
            <div className="controls">{controls}</div>
          </div>
          <NetworkCheck
            key={key}
            batch={false}
            showRunning={true}
            showPassing={true}
            showFailing={true}
            onPass={this.handlePass}
            onFail={this.handleFail}
            onWarn={this.handleWarn}
          />
          <NetworkInfo addr={this.state.addr} />
        </div>
        <div className="footer">
          <a target="_blank" href="https://www.screenscape.com/support/screenscape-network-check/">
            How to use this page
          </a>
          <a target="_blank" href="https://www.screenscape.com/">
            Powered by ScreenScape
          </a>
        </div>
      </div>
    );
  }
}

ReactDOM.render(<Application />, document.querySelector('#app'));
