import uuid from 'uuid/v4';
import propTypes from 'prop-types';
import React, { Component } from 'react';

import Check from './check';

import { fetchCheckStatus, fetchChecksManifest } from '../lib/shared';
import { CHECK_STATUS_PASS, CHECK_STATUS_FAIL, CHECK_STATUS_LOADING, CHECK_LEVEL_FATAL } from '../lib/constants';

class NetworkStatus extends Component {
  static defaultProps = {
    showPassing: true,
    showRunning: true,
    showFailing: true,
    limit: 9999,
    batch: false,
    groups: [],
    onPass: () => {},
    onFail: () => {},
    onWarn: () => {},
  };

  static propTypes = {
    showRunning: propTypes.bool,
    showPassing: propTypes.bool,
    showFailing: propTypes.bool,
    groups: propTypes.array,
    limit: propTypes.number,
    batch: propTypes.bool,
    onPass: propTypes.func,
    onFail: propTypes.func,
    onWarn: propTypes.func,
  };

  state = {
    checks: {},
    loading: true,
    error: false,
  };

  async componentDidMount() {
    try {
      const checks = await fetchChecksManifest();
      await this.initializeChecks(checks);
    } catch (err) {
      this.setState(
        {
          checks: {
            [uuid()]: {
              title: 'ScreenScape',
              error: 'Failed to connect to https://screenscape.com/',
              feedback:
                'Unable to reach ScreenScape services. Please ensure the network you are connected to has internet access.',
              level: CHECK_LEVEL_FATAL,
              status: CHECK_STATUS_FAIL,
            },
          },
        },
        () => {
          setTimeout(() => {
            this.props.onFail();
          }, 2000);
        }
      );
    }
  }

  async initializeChecks(checks) {
    const { groups } = this.props;

    const activeChecks =
      groups.length === 0
        ? checks
        : checks.filter(check => {
            for (const group of check.groups) {
              if (groups.includes(group)) {
                return true;
              }
            }
            return false;
          });

    this.setState(
      {
        checks: activeChecks.reduce((acc, check) => {
          acc[uuid()] = { ...check, status: CHECK_STATUS_LOADING };
          return acc;
        }, {}),
      },
      () => {
        this.processChecks();
      }
    );
  }

  processChecks() {
    return this.props.batch ? this.processChecksBatch() : this.processChecksAsync();
  }

  processChecksAsync() {
    const { checks } = this.state;
    const requests = [];
    Object.entries(checks).forEach(([key, check]) => {
      const request = fetchCheckStatus(check);
      requests.push(request);
      request.then(checked => {
        this.setState(prev => ({
          checks: {
            ...prev.checks,
            [key]: checked,
          },
        }));
      });
    });
    Promise.all(requests).then(this.handleCheckingComplete);
  }

  async processChecksBatch() {
    const { checks } = this.state;
    const keys = Object.keys(checks);
    const checked = await Promise.all(Object.entries(checks).map(([key, check]) => fetchCheckStatus(check)));
    this.setState({
      loading: false,
      checks: checked.reduce((acc, check, i) => {
        acc[keys[i]] = check;
        return acc;
      }, {}),
    });
    this.handleCheckingComplete(checked);
  }

  handleCheckingComplete = checks => {
    const { onFail, onWarn, onPass, limit } = this.props;

    const failedChecks = checks.filter(({ status }) => status === CHECK_STATUS_FAIL).slice(0, limit);

    if (failedChecks.length > 0) {
      if (checks.some(({ level }) => level === CHECK_LEVEL_FATAL)) {
        onFail(failedChecks);
      } else {
        onWarn(failedChecks);
      }
    } else {
      onPass();
    }
  };

  render() {
    const { checks } = this.state;
    const { showPassing, showRunning, showFailing, limit } = this.props;
    const displayed = Object.entries(checks)
      .filter(
        ([key, check]) =>
          (showRunning && check.status === CHECK_STATUS_LOADING) ||
          (showPassing && check.status === CHECK_STATUS_PASS) ||
          (showFailing && check.status === CHECK_STATUS_FAIL)
      )
      .slice(0, limit)
      .reduce((acc, [key, check]) => {
        acc[key] = check;
        return acc;
      }, {});

    return Object.entries(displayed).map(([key, check]) => <Check key={key} {...check} border />);
  }
}

export default NetworkStatus;
