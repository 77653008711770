export const CHECK_STATUS_PASS = 'pass';
export const CHECK_STATUS_FAIL = 'fail';
export const CHECK_STATUS_LOADING = 'loading';
export const CHECK_LEVEL_WARN = 'warn';
export const CHECK_LEVEL_FATAL = 'fatal';
export const CHECK_TYPE_TCP = 'tcp';
export const CHECK_TYPE_HTTP = 'http';
export const ALL_RESULT_LOADING = 'loading';
export const ALL_RESULT_PASS = 'passed';
export const ALL_RESULT_FAIL = 'failed';
export const ALL_RESULT_WARN = 'warned';
