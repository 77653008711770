import React from 'react';
import styles from './check.scss';

const NetworkInfo = ({ addr }) => {
  return (
    <div className={`${styles.check} check-wrapper`}>
      <div className={`${styles.head} check-head`}>
        <div className={`${styles.title} check-head-title`}>IP Address:</div>
        {addr}
      </div>
    </div>
  );
};

export default NetworkInfo;
