import React from 'react';

import styles from './spinner.scss';

const Spinner = () => (
  <div className={styles.ldsring}>
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default Spinner;
